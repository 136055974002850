import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { PressReleaseView } from "components/views/PressReleaseView/PressReleaseView"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { Helmet } from "react-helmet"
import { helmetJsonLdProp } from "react-schemaorg"
import { Article } from "schema-dts"

export interface ContentfulPressRelease {
  id: string
  title: string
  publishDate: string
  schemaPublishDate: string
  excerpt: {
    excerpt: string
  }
  slug: string
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>
  featuredImage: {
    gatsbyImageData: IGatsbyImageData
    description: string
  }
  metaDescription: string
  metaImage: {
    gatsbyImageData: IGatsbyImageData
    file: {
      url: string
    }
  }
  metaTitle: string
  metaNoIndexNoFollow?: boolean
}
interface PressReleaseProps {
  location: WindowLocation
  data: {
    contentfulPressRelease: ContentfulPressRelease
  }
}

const PressRelease = ({ data, location }: PressReleaseProps): JSX.Element => {
  return (
    <>
      <Seo
        description={data.contentfulPressRelease.metaDescription}
        image={data.contentfulPressRelease.metaImage?.gatsbyImageData}
        pathname={location.pathname}
        title={data.contentfulPressRelease.metaTitle}
        noIndexNoFollow={data.contentfulPressRelease.metaNoIndexNoFollow ?? false}
      />
      <Helmet
        script={[
          helmetJsonLdProp<Article>({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            abstract: data.contentfulPressRelease.excerpt?.excerpt,
            headline: data.contentfulPressRelease.title,
            ...(data.contentfulPressRelease.metaImage && {
              thumbnailUrl: `https:${data.contentfulPressRelease.metaImage.file.url}`,
            }),
            datePublished: new Date(data.contentfulPressRelease.schemaPublishDate).toISOString(),
            sourceOrganization: {
              "@type": "Organization",
              name: "Einride",
            },
          }),
        ]}
      />
      <PressReleaseView pressRelease={data.contentfulPressRelease} />
    </>
  )
}

export const query = graphql`
  query PressReleaseQuery($id: String) {
    contentfulPressRelease(id: { eq: $id }) {
      id
      title
      publishDate(formatString: "M.D.YYYY")
      schemaPublishDate: publishDate(formatString: "YYYY/MM/DD")
      excerpt {
        excerpt
      }
      slug
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            file {
              contentType
              url
            }
            gatsbyImageData
            title
          }
          ... on ContentfulMediaKit {
            __typename
            contentful_id
            fileUrl
            thumbnail {
              gatsbyImageData
            }
            title
          }
          ... on ContentfulNews {
            __typename
            contentful_id
            slug
            title
          }
          ... on ContentfulPressRelease {
            __typename
            contentful_id
            slug
            title
          }
        }
      }
      featuredImage {
        gatsbyImageData(aspectRatio: 2.3, layout: FULL_WIDTH)
        description
      }
      metaDescription
      metaImage: featuredImage {
        gatsbyImageData(width: 1200, height: 630)
        file {
          url
        }
      }
      metaTitle
      metaNoIndexNoFollow
    }
  }
`

export default PressRelease
