import { Icon, PrimaryButton, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { RichText } from "components/contentful-ui/RichText/RichText"
import { PressIndex } from "components/fragments/PressIndex/PressIndex"
import { Col, ContentWrapper, Row } from "components/shared/styles/Layout"
import { Col2 } from "components/ui/Col2/Col2"
import { Layout } from "components/ui/Layout/Layout"
import { Link } from "components/ui/Link/Link"
import { PageHero } from "components/ui/PageHero/PageHero"
import { Paragraph, Title1 } from "lib/ui/Typography/Typography"
// eslint-disable-next-line import/extensions
import { ContentfulPressRelease } from "pages/press/{contentfulPressRelease.slug}"

interface PressReleaseViewProps {
  pressRelease: ContentfulPressRelease
}

export const PressReleaseView = ({ pressRelease }: PressReleaseViewProps): JSX.Element => {
  const { body, featuredImage, publishDate, title } = pressRelease

  return (
    <Layout>
      <PageHero image={featuredImage?.gatsbyImageData}>
        <Title1>{title}</Title1>
      </PageHero>
      <ContentWrapper>
        <Col2>
          <Paragraph>{publishDate}</Paragraph>
          <RichText body={body} />
        </Col2>
        <VerticalSpacing size="lg" />
        <Row>
          <Col width={1 / 2} />
          <Col width={1 / 2}>
            <Title1>Go Einride</Title1>
            <VerticalSpacing />
            <Paragraph>
              Access the clean, safe and efficient way to ship. With electric and autonomous
              vehicles coordinated by an intelligent network, you can go green and future-proof your
              business.
            </Paragraph>
            <Link to="contact">
              <StyledWithIconButton rightIcon={<Icon name="arrowRight" />}>
                Connect with us
              </StyledWithIconButton>
            </Link>
          </Col>
        </Row>
        <VerticalSpacing size="lg" />
        <PressIndex />
        <VerticalSpacing size="xl" />
      </ContentWrapper>
    </Layout>
  )
}

const StyledWithIconButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: ${({ theme }) => 3 * theme.spacer}px;

  @media ${({ theme }) => theme.mediaQueries.md} {
    width: unset;
    margin-top: ${({ theme }) => 2 * theme.spacer}px;
  }
`
